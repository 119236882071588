/*-----Common Css-----*/
.value_ {
    color: #ACACAC;
    font-size: 12px;
}

select:focus {
    outline: none !important;
}

.secondaryBtnGrey {
    background: transparent;
    color: #BFBFBF;
    border-radius: 5px;
    padding: 10px 20px !important;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #BFBFBF;
}

.secondaryBtnGrey2 {
    background: #797979;
    color: #Fff;
    border-radius: 5px;
    padding: 10px 25px !important;
    font-size: 12px;
    font-weight: 500;
    border: none;
}

.subsmallBtnblue_ {
    background: #2F4894;
    border-radius: 5px;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    padding:10px 20px;
}

.subsmallBtngreen_ {
    background: #5CC13E;
    border-radius: 5px;
    padding: 10px 23px;
    font-size: 13px;
    color: #fff;
    font-weight: 500;
}

.btnNobg_ {
    background: #344D99 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    padding: 9px 26px;
}

.smallgreyBtn_ {
    background: #393939;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    padding: 9px 14px;
}

.boldmainHeading_ {
    font-size: 27px;
    font-weight: 600;
    color: #343434;
    margin: 0;
}

.memberHead_ {
    font-size: 27px;
    color: #3B57A9;
}

.discription_ {
    color: #343434 !important;
    font-size: 16px;
}

/*Cutom Checkbox Css*/
.checkmark_ {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 0 0 13px;
    justify-content: space-between;
}

.checkmark_ input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
}

.checkmark_ input[type="checkbox"]+label {
    display: inline-flex;
    font-size: 15px;
    position: relative;
}

.checkmark_ input[type="checkbox"]:checked+label:before {
    background: #5CC13E;
    border: 1px solid #5CC13E !important;
}

.checkmark_ input[type="checkbox"]:checked+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 9px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

input[type="checkbox"]:checked+label::after {
    opacity: 1;
}

.checkmark_ label::before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    padding: 7px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 7px;
    width: 18px;
    height: 18px;
    left: 2px;
    border: 2px solid #D3D3D3;
    border-radius: 5px;
    top: 0px;
}

/*Cutom Checkbox Css*/
/*Modal Css*/
.modal-content {
    background-color: #ffff;
    border-radius: 15px !important;
}

/*-----Common Css-----*/
/*--Start Membership Header--*/
.membershipAuthNav {
    position: relative;
    background-image: url(../images/smallbg_.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px 0;
}

.membershipAuthNav .navbar-brand {
    line-height: 0;
    /* padding: 0; */
}

/*--End Membership Header--*/
/**Start Membership Css**/
.memberShiphead {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.memberImage {
    width: 100%;
}

.memeberHead_ {
    text-align: center;
    display: inline-block;

}

.memberborder::before {
    position: absolute;
    content: '';
    width: 82%;
    left: 0px;
    background: #6E6E6E;
    height: 1px;
    left: 0;
    margin: 0 auto;
    right: 0;
}



.growBusiness_ {
    display: inline-block;
    width: 100%;
    padding: 0 7px;
}

.growMainbox_ {
    display: flex;
    justify-content: space-between;
}

.growbusinessLeft_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.growbusinessAbout_ .memberHead_ {
    font-weight: 600;
    margin: 0 0 4px;
}

.growImage {
    margin: 0;
}

.growImage img {
    width: 70px;
    margin-right: 15px;
}

.growblueHead_ {
    font-size: 18px;
    color: #3B57A9;
    font-weight: 600;
}

.yearBadge {
    display: inline-block;
    background: #E1E1E1 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 9px 19px;
    font-size: 11px;
    font-weight: 500;
    margin: 7px 0 0;
}

.growbusinessRight_ {
    display: inline-block;
    text-align: right;
    width: 33%;
}

.listHead_ {
    color: #343434;
    font-size: 13px;
}

.check_ {
    width: 10px;
    margin-right: 6px;
}

.featureListbox_ li {
    line-height: 27px;
    /* display: inline-block; */
    width: 100%;
    display: flex;
    align-items: start;
}
.featuresList_ li .check_{
    margin-top:9px;
}
.memberBottom_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.memberBottom_ p {
    font-size: 16px;
    color: #343434;
    margin: 0;
}

.businessPlans_ {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 30px #00000005;
    border-radius: 15px;
    display: inline-block;
    width: 100%;
    padding: 19px 25px;
}

.otherHeading_ {
    font-size: 38px;
    color: #1C3276;
}

.faqQuestionBox_ .innerSubtext {
    font-weight: 600;
    margin-bottom: 20px;
}

.frequentlyQuestion {
    padding: 0px 0;
}

.innerfawBox_ {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
}

.paragraph_ {
    color: #343434;
    font-size: 15px;
    line-height: 26px;
}

.faqQuestionBox_ .paragraph_ {
    color: #2b2b2b;
    font-size: 14px;

}

/**End Membership Css**/
/**Start TermsCondition Css**/
.termspara_ {
    font-size: 16px;
    color: #343434;
    line-height: 30px;
    margin-bottom: 20px;
}

/**End TermsCondition Css**/
/**Start Faq Css**/
.contectUs_ .memberHomeImage_ {
    padding: 70px;
}

/**End Faq Css**/
/**Start Contact us**/
.contactRight .form-control {
    border: 1px solid #6E6E6E;
    border-radius: 5px;
    color: #5E5F5E;
    font-size: 15px;
    padding: 17px 17px;
}

.contactRight .primaryBtn {
    width: 100%;
}

.contactLeft {
    display: inline-block;
    width: 100%;
    position: relative;
    height: 100%;
}

.contactIcon {
    width: 29px;
    margin-right: 5px;
}

.contactInfo_ {
    /* position: absolute; */
    /* bottom: 0; */
}

.contactHead_ {
    color: #3B57A9;
    font-size: 50px;
}

/**End Contact us**/
/*-----Start Business Search-----*/
/*Start Left Side Css*/
.blueHead_ {
    color: #435FB5;
    font-weight: 800;
}

.headTextmain_ {
    font-size: 27px;
    color: #343434;
}

.business_search {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    border: 1px solid #d8d8d8;
    padding: 12px;
    border-radius: 4px;
}

.searchIcon_ {
    margin-right: 6px;
    width: 18px;
    height: 18px;
}

.business_search .custoInput {
    font-size: 13px;
}

.business_search .custoInput::placeholder {
    color: #A9A9A9;
}

.businessDetail_ {
    display: inline-block;
    width: 100%;
    padding-right: 9px;
}

.businessType_ {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #D7D7D7;
}

.businessType_ .headingSmall_ {
    font-weight: 600;
}

.cateSelectbox {
    background: #FFFFFF;
    border: 1px solid  #d8d8d8;
    border-radius: 4px;
    display: inline-block;
    width: 100%;
    padding: 14px 25px 14px 10px;
    color: #3B3B3B;
    font-size: 12px;
    appearance: none;
    background-image: url(../images/downArrow.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 29px 8px;
}
.newPostOpportunity .cateSelectbox{
    background-image:url(../images/downDark.svg)!important;
}

.recentlyAdded{
    background-image: url(../images/downDark.svg) !important;
    font-size: 17px !important;
    color: #606060 !important;
    background-position: right 4px !important;
    max-width: 164px !important;
    background-size: 16px 16px !important;
}
.businessBtn .primaryBtnSmall {
    background-color: #49a82e;
    font-size: 15px;
    color: #fff;
    padding: 15px 0 !important;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
}
.businessBtn .secondaryBtnGrey{
    font-size: 15px;
    color: #BFBFBF;
    padding: 15px 0 !important;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

}
.businessBtn .secondaryBtnOuter{
    font-size: 15px;
    color: #fff;
    padding: 15px 0 !important;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
}

.select__control {
    min-height: 50px !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    border: 1px solid rgba(76, 76, 76, .19) !important;
}

.tagsType .select__multi-value {
    background: #4D6AC4;
    color: #fff;
    padding: 6px 5px;
    border-radius: 8px;
}

.tagsType .select__multi-value__label {

    color: #fff;
}

.tagsType .select__placeholder {
    font-size: 14px;
    color: #3B3B3B;
}

.lookSuplier {
    display: inline-block;
    width: 100%;
    position: relative;
}

.suplierImg_ {
    width: 100%;
    border-radius: 8px;
}

.supText_ {
    background: #4D6AC4;
    padding: 12px 10px;
    border-radius: 0px 0 8px 8px;
    position: absolute;
    bottom: 2px;
    width: 100%;
    text-align: center;
}

.supText_ h5 {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.containerCustom {
    height: auto;
}
.innerSidebar{
    height: auto;
}
.businessText_ {
    background: transparent linear-gradient(0deg, #439E28 0%, #5CC13E 100%) 0% 0% no-repeat;
    border-radius: 5px;
    padding: 10px 26px;
    text-align: center;
    position: absolute;
    bottom: 54px;
    left: 0;
    right: 0;
    max-width: 281px;
    margin: 0 auto;
    width: 100%;
}

.businessText_ h5 {
    font-size: 17px;
    color: #fff;
    margin: 0;
}

.select__indicator-separator {
    display: none;
}

.selectListbox {
    display: flex;
    align-items: center;
    justify-content: end;
}
.tagsType{
    position: relative;
}

.tagsType .inputIcon {
    position: absolute;
    right: 10px;
    bottom: 17px;
    color: #ccc;
}
.radioCheck_ {
    display: inline-block;
    width: auto;
    margin-right: 20px;
    margin-bottom: 0;
}
.selectListbox  .radioCheck_ {
    margin-left: 15px;
    margin-right: 0 !important;
}


.radioCheck_ input[type="radio"]:checked+label::after {
    top: 4px !important;
}

/*End Left Side Css*/
/*Start Right Side Css*/
.topPagefilter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.topPagefilter .cateSelectbox {
    max-width: 131px;
    width: 100%;
    border: none;
    border-bottom: 1px solid  #d8d8d8;
    border-radius: 0;
    margin-right: 10px;
    color: #4C4C4C;
    font-size: 11px;
    padding: 2px 6px 7px;
    background-size: 23px 6px;
}

.designcontentTop_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.opportunitiesPlans{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:15px 0;
}
.opportunitesMonth{
    font-size: 17px;
    color: #181818;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.opportunitesMonth img{
    width: 22px;
    height: 22px;
}
.companyName_{
    font-size: 19px;
    color: #363636;
}
.opportunitesPosted {
    font-size: 13.14px;
    color: #2c2c2c;
    font-weight: 300;
}
.designLeft_ .weeksTxt {
    margin: 3px 0 0;
}

.badgeImg {
    margin-left: 10px;
}

.designbottonContent_ {
    position: absolute;
    bottom: 0;
    z-index: 999;
    background: #FFF;
    width: 100%;
}

.designContent_ {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
}

.statusBtn_ {
    background-color: rgb(76, 76, 76, .11);
    border-radius: 5px;
    display: inline-block;
    padding: 8px 15px;
    font-size: 12px;
    margin-right: 10px;
}
.businessDesincontent_ {
    display: inline-block;
    width: 100%;
}
.reviewRespondent_ .designLeft_ h4{
    font-size: 18px;
    color:#737373;
    font-weight: 400;
}
.reviewRespondent_ .designLeft_ h5{
    font-size: 16px;
    color:#404040;
    font-weight: 800;
}
.reviewRespondent_ .designLeft_ .weeksTxt{
    font-weight: 600;
    font-size: 16px;
}
.reviewResponseBtns .applicationBtn_{
    background: #3b3b3b;
    padding: 9px 15px;
    border-radius: 5px;
}
.reviewResponseBtns .contactBtn_{
    background: #22ba22;
    color: #fff;
    font-size: 17px;
    border-radius: 5px;
    padding: 9px 20px;
}
.reviewRespondent_ .motionLoc .textInnerOuter{
    font-size: 16px;
}
.reviewRespondent_ .innerSubtextSmall{
    font-size: 16px !important;
    color: #737373 !important;
}
.viewProfileDetails .memberblueHead_{
    font-size: 16px;
    font-weight: 700;
}
.viewProfileImg{
    border-radius: 10px;
}
.viewProfileDetails .discription_{
    font-size: 14px;
}
.bottomBorder_::before {
    position: absolute;
    content: '';
    width: 93%;
    left: 0px;
    background: #6E6E6E;
    height: 1px;
    left: 0;
    margin: 0 auto;
    right: 0;
}

.bottomBorder_ {
    margin: 19px 0 3px;
}

.certified_ {
    background: transparent linear-gradient(357deg, #3B9122 0%, #5CC13E 100%);
    border-radius: 8px;
    padding: 7px 31px;
    display: inline-block;
}

.bridgeImg {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.bridgeImg img {
    height: 273px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 40%;
}

/*Start View Profile Css*/
.aboutCopany_ .moreOpportunities:last-child{
margin-bottom: 0;
padding-bottom: 8px;
border-bottom: 0;
}
.backToListing{
    font-size: 13px;
    padding: 10px 20px;
    font-weight: 600;
}
.moreOpportunities{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 31px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 31px;
}
.moreOppRight{
    display: flex;
    align-items: center;
}
.moreOppLeft h4{
    color:#364f9e;
    font-size: 18px;
    font-weight: 700;
}
.moreOppLeft p{
    font-size: 17px;
    color:#6a6a6a;
}
.moreOppRight p{
    font-size: 17px;
    color:#6a6a6a;
}
.moreOppRight p span{
    font-size: 17px;
    color:#393838;
}
.moreOppRight button{
    font-size: 13px;
    font-weight: 600;
    padding:10px 20px;
    border-radius: 7px;
    background-color:#4764ba ;
    color:#fff;
}
.viewProfile_ .designbtnRight{
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: transparent !important;
}
.viewProfile_ .todoList_{
    width: 28px;
}
.viewProfile_ .recentlyDataList {
    margin: 18px 0;
}
.viewProfile_ .designLeft_ .boldmainHeading_{
    font-size: 36px;
}
.viewProfile_ .designLeft_ {
    align-items: start !important;
}
.viewProfile_ .aboutCopany_ .boldmainHeading_{
    font-size: 24px;
    font-weight: 800;
}
.viewProfile_ .designbtnRight p{ 
    background-color: transparent !important;
    font-size: 17px;
    color: #393838;
}
.designbtnRight button{
    /* min-width: 125px; */
}
.businessServer_ .designbottonContent_ {
    position: relative;
}

.locationImg_ {
    width: 11px;
    margin-right: 4px;
}
.opportunityPage .textInnerOuter{
    font-size: 17px;
}
.businessServer_ .designLeft_ {
    display: flex;
    align-items: center;
}
.todoList_ {
    width: 32px;
    margin-right: 7px;
    height: 40px;
}
.postHours_ {
    background: #ebebeb;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px 13px;
    margin: 0;
    font-weight: 600;
    color: #a2a1a1;
    display: inline-block;
}

.detailInformation_ li:before {
    content: "";
    width: 7px;
    height: 7px;
    background: #4C4C4C;
    position: absolute;
    border-radius: 20px;
    left: -17px;
    top: 5px;
}

.detailInformation_ li {
    position: relative;
}

.detailInformation_ {
    display: inline-block;
    width: 100%;
    padding-left: 18px;
}

.aboutCopany_ .headingSmall_ {
    font-weight: 600;
}

.serverInformation_ .innerSubtextSmall {
    line-height: 19px;
}

.contractDetails_ .innerSubtextSmall {
    line-height: 19px;
}

.aboutCopany_ .imgproduct_ {
    width: 100%;
}
.diverseCard{
    position: static;
}
.diverseCard .cardBodyArea{
    padding:35px 20px 26px 27px;
    background-color: #4d6ac4;
}
.diverseCard .cardBodyArea h5{
    color:#fff;
    font-size: 23px;
    font-weight: 400;
}
.diverseCard .cardBodyArea p {
    color: #fff;
    font-size: 18px;
}
.diverseCard.bridge p{
    font-size: 16px;
}
.diverseCard.bridge .outerBTN {
    border: 1px solid #fff;
    background: transparent;
    border-radius: 5px;
}
.diverseCard .cardImg img{
    min-width: 286px !important;
    height: 100%;
    min-height: 175px;
    object-position: 12% 70% !important;
}
.diverseCard .cardBodyArea .primaryBtn {
    font-size: 13px !important;
    padding: 10px 20px !important;
    font-weight: 600 !important;
}
.diverseCard.bridge img {
    min-width: 430px !important;
    height: 100%;
    min-height: 158px;
    object-position: left !important;

}
/*Start View Profile Css*/
/*-----End Business Search-----*/
/**Start Resources Css**/
.commonContentBox .homeBanner {
    padding-top: 161px;
    padding-bottom: 44px;
}

.commonContentBox .aboutContainer {
    padding-top: 12rem;
    padding-bottom: 4rem;
}

.bussinessResoureCon .aboutContainer {
    padding-top: 220px;
    padding-bottom: 10px;
}
.bussinessResoureCon .aboutContainer .bannerHead {
    font-size: 45px;
    padding-bottom: 20px;
}
.bussinessResoureCon .aboutContainer .bannerTxt {
    font-size: 21px;
}
.anotherHeading {
    font-size: 30px;
    color: #FFFFFF;
    font-weight: 400;
    /* line-height: 45px; */
}

.commonContentBox .aboutFounder .anotherHeading {
    padding-right: 48px;
}

.commonContentBox .aboutFounder {
    padding: 7em 0;
}

.streamImgchat {
    position: absolute;
    bottom: -29px;
    left: -29px;
    width: 11%;
}

.liveStreame {
    position: relative;
}

.streamImg {
    width: 96%;
}

.premiumSoundbite_ {
    display: inline-block;
    width: 100%;
    padding: 80px 0;
}

.premiumbite_ {
    background: #fff;
    display: inline-block;
    width: 100%;
}

.biteImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bitesLeftImg {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.noPadding {
    padding-left: 0;
    padding-right: 0;
}

.bitesRight_ {
    padding: 20px 0 20px 0;
}

.topbites .statusBtn_ {
    background-color: #F8F8F8;
    margin: 0;
}

.topbites {
    display: flex;
    justify-content: flex-end;
}

.subHeading__ {
    font-size: 14px;
    font-weight: bold;
    color: #343434;
}

.workDealing {
    padding: 0 56px 0 22px;
}

.bitesCrad {
    background: #fff;
    box-shadow: 0px 20px 30px #00000005;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    /* height: 100%; */
}

.soundbiteImg {
    width: 100%;
    height: 180px;
    border-radius: 10px 10px 0 0px;
}

.soundbiteContent {
    display: inline-block;
    width: 100%;
    padding: 22px 20px;
    height: 100%;
    min-height: 181px;
}

.soundbiteContent .listHead_ {
    margin: 0;
}

.mainSoundbites_ {
    padding-bottom: 80px;
}

.sectionDisable {
    opacity: .2;
}

.mainstagebox_ {
    position: relative;
}

.mainstagebtn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 447px;
    top: 33%;
}
#soundbytesThumbnail .bitesCrad video {
    width: 100%;
    height: 100%;
    max-height: 500px;
    border-radius: 10px 10px 0px 0px;
}
/**End Resources Css**/
/**Start Faq Css**/
.faq-container {
    max-width: 600px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.faqcontentBox_ .row-content-text {
    font-size: 14px !important;
    color: #343434;
    line-height: 28px;
}

.faqcontentBox_ {
    padding: 15px 0;
}

.faqcontentBox_ .row-title.closed {
    padding: 0 !important;
}

.faqcontentBox_ .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
    padding: 10px 0 14px !important;
}

.faq {
    box-sizing: border-box;
    background: transparent;
    padding: 30px;
    position: relative;
    overflow: hidden;
}

.faqImg {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.faqImgbox {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: right;
    padding-right: 50px;
}

.faq-title {
    margin: 0 35px 0 0;
}

.faqcontentBox_ .styles_icon-wrapper__2cftw {
    width: 22px;
    height: 22px;
    background: #3B57A9;
    text-align: center;
    border-radius: 50px;
    color: #fff !important;
    font-weight: 600;
    line-height: 19px;
    font-size: 15px;
}

.faqcontentBox_ .styles_closed__39w54 .styles_icon-wrapper__2cftw {
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
}

.faqcontentBox_ .row-title-text {
    font-weight: 600;
}

/**End Faq Css**/
/**Start Company Front Profile **/
.companyDetailspro_ {
    /* display: inline-block; */
    width: 100%;
    /* margin-top: -76px; */
}
.companyDetailsLeft {
    margin-top: -76px;
}

.abouText_ {
    font-size: 13px;
    color: #3B57A9;
    font-weight: 600;
}

.companyDetailsLeft .imgproduct_ {
    width: 100%;
    box-shadow: -3px 5px 38px 5px rgb(0 0 0 / 8%);
    border-radius: 8px;
    object-fit: cover;
    background: #ffff;
    border: 1px solid #c3c3c3;
    padding: 2px;
    min-width: 164px;
    min-height: 152px;
}

.companyDetailsLeft .designImg_ {
    display: inline-block;
    width: 100%;
    position: relative;
    /* top: -62px; */
    max-width: 150px;
    margin-top: -62px;

}

.currentTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.opportunityHead_ {
    font-size: 14px;
    color: #364F9E;
    font-weight: 700;
}

.lightgreyHead_ {
    font-size: 12px;
    color: #808080;
}

.opportunityComtent_ .postHours_ {
    display: inline-block;
}

.opportunityComtent_ {
    display: inline-block;
    width: 100%;
    padding: 0 26px;
    position: relative;
    /* border-right: 1px solid #DFDFDF; */
    margin-bottom: 30px;
}

.opportunityComtent_ .wordLimit{
    min-height: 54px;
}
.opportunityComtent_:after {
    content: "";
    width: 1px;
    height: 100%;
    background: #DFDFDF;
    position: absolute;
    top: 0;
    left: -17px;
}
.noOpportunity::after{
    opacity: 0;

}
.newsBox_ .soundbiteContent {
    padding: 22px 0;
    border-bottom: 1px solid #d1d1d1;
    min-height: 167px;
}

.bluenewHead_ {
    font-size: 12px;
    color: #364F9E;
}

.newsBottom_ {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.newsBox_ .soundbiteContent .listHead_ {
    font-size: 12px;
}

.newsBottom_ img {
    width: 15px;
}

.likeeImage_ {
    margin-right: 12px;
}

.companyDetailsRight .headingTitle_ {
    font-size: 20px;
}

.deviserCompany_ .headingTitle_ {
    font-size: 18px;
    font-weight: 500;
}

.blueinnerSubtext {
    font-size: 15px;
    color: #2F4894;
    font-weight: 500;
}

.companyDetailsList li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #d5d5d5;
}
.companyDetailsList li:last-child {
    border-bottom: 0;
}

/* .companyDetailsList .listHead_ {
    font-weight: 600;
} */

.containerBoxright {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 18px;
    position: relative;
}

.deviserCompany_ .subsmallBtnblue_ {
    padding: 10px 20px;
    font-size: 13px;
    font-weight: 600 !important;
}

.deviserCompany_ p {
    font-size: 12px;
    color: #4C4C4C;
    margin: 12px 0 0;
}

.deviserCompany_ .designbottonContent_ {
    position: relative;
    margin: 17px 0 0;
}

.deviserCompany_ .statusBtn_ {
    padding: 8px 9px;
}

.whatnextBox .supText_ {
    text-align: left;
    position: relative;
    padding: 15px 15px;
}

.whatnextBox .supText_ h6 {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
}

.whatnextBox .supText_ p {
    font-size: 12px;
    color: #FFFFFF;
    margin: 0;
}

.certifyBadge {
    width: 30px;
    height: 30px;
}

.deviserCompany_ .posts .headingTitleSmall_ {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.docText {
    color: #364F9E;
    cursor: pointer;
    font-weight: 600;
}

.deviserCompany_ .posts {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 14px;
}

.deviserCompany_ .posts:last-child {
    border: none;
}

.certificationContainer_ {
    background: #fff;
    border-radius: 8px;
    /* height: 100%;
    max-height: 411px; */
    overflow: auto;
}
.deviserCompany_{
    height: 100%;
    max-height: 411px;
    overflow: auto;
}
.userOppertunities .deviserCompany_{
    height: 100%;
    max-height: 460px;
    overflow: auto;
}
.userOppertunities .deviserCompany_ .certificationContainerBox_:nth-child(4){
   margin-bottom: 0 !important;
}
.certificationContainer_::-webkit-scrollbar {
    display: none;
}

.rating_ .MuiRating-root {
    margin: 6px 0 0;
    color: #2f4894;
}

.rating_ .css-1c99szj-MuiRating-icon {
    color: #2f4894;
}

.rating_ .css-1bg55nt-MuiRating-icon {
    transform: initial;
}

/**Start Company Front Profile **/
/**Start All Connection Css**/

.allConnection_ .motionSecData {
    text-align: left;
}

.allConnection_ .companyDetailsItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 24px;
}

.smallheadingBlue_ {
    font-size: 16px;
    color: #2F4894;
    font-weight: 500;
}

.allConnection_ .postContainer .postContainerArea:first-child {
    margin-top: 0;
    border-top: 0;
    padding-top: 0;
}

.allConnection_ .messageContainer {
    height: 431px;
}

.allConnection_ .cardBodyArea h5 {
    font-size: 17px;
}

.acceptBtn_ {
    background: #344D99;
    color: #fff;
    font-size: 10px;
    border-radius: 5px;
    padding: 5px 11px;
    margin-right: 10px;
}

.crossBtn_ {
    width: 21px;
}

.allConnection_ .postSec {
    padding: 0 18px 20px;
}

.allConnection_ .postsDetails .weeksTxt {
    font-weight: 500;
    margin: 0;
}

.topLightboder {
    height: 1px !important;
    background: rgb(95 96 94 / 20%);
    opacity: 1;
}

.messagebtn {
    display: flex;
    justify-content: end;
}

.messagebtn .subsmallBtnblue_ {
    padding: 8px 12px;
}

.allConnection_ .postsDetails {
    display: inline-block;
    width: 100%;
}

/**Start All Connection Css**/
/**Start Registration**/
.companyInfobox {
    display: inline-block;
    width: 90%;
    margin: 0 auto;
}

.uploadPhotobox_ .dzu-inputLabel {
    color: #858587;
    font-weight: 700;
    font-size: 16px;
}

.navboxRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbrandLeft_ .navbar-brand {
    padding: 0;
    line-height: 0;
}

.rightNav_ {
    display: flex;
    justify-content: end;
    width: 100%;
    align-items: center;
}

.rightNav_.searchNav {
    justify-content: space-between;
}

/* .navheadInner #basic-navbar-nav {
    border-right: 2px solid #647abf;
    margin-right: 21px;
    padding-right: 10px;
} */

.navheadInner .nav-link {
    padding: 0 10px;
}

.uploadPhotobox_ .dzu-inputLabelWithFiles {
    position: absolute;
    left: 0;
    z-index: 8;
}

.uploadPhotobox_ .dzu-submitButtonContainer {
    margin: 24px 0;
    z-index: 1;
    position: absolute;
    left: 76px;
    bottom: -43px;
}

.uploadPhotobox_ .dzu-previewContainer {
    padding: 40px 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    position: relative;
    width: 100%;
    min-height: 60px;
    z-index: 1;
    border-bottom: none;
    box-sizing: border-box;
}

.uploadPhotobox_ .dzu-inputLabelWithFiles {
    position: absolute;
    left: 0;
    z-index: 8;
    background: #f7f7f7;
    height: 157px;
    width: 181px;
    border: 2px dashed #cdcbcb;
    color: #7a7676;
    margin-bottom: 28px;
}

.uploadPhotobox_ .dzu-previewImage {
    width: 65px;
    border-radius: 4px;
    height: 65px;
    margin-right: 10px;
}

.uploadPhotobox_ .dzu-submitButton {
    padding: 8px 32px;
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    color: #fff !important;
}

.uploadPhotobox_ .dzu-submitButtonContainer {
    margin: 0;
    z-index: 1;
    position: absolute;
    left: 48px;
    bottom: 22px;
}

.uploadPhotobox_ .dzu-dropzone {
    background: #f7f7f7 !important;
    min-height: 256px !important;
    border: 2px dashed #d9d9d9 !important;

}

.uploadPhotobox_ .dzu-dropzone::-webkit-scrollbar {
    display: none;
}

.uploadPhotobox_ .dzu-previewButton {
    background-size: 11px 10px;
    width: 10px;
    height: 10px;
    filter: opacity(0.7);
}

.fileuploadBtn_ {
    display: flex;
    justify-content: end;
}

.editProfilebox_ .customFormControl::placeholder {
    font-size: 12px !important;
}

.editProfilebox_ .customFormControl {
    padding: 8px 12px;

}

.w3-round {
    width: 41px;
    height: 41px;
    background: #cfcfcf;
    padding: 3px;
    border-radius: 4px;
    margin-right: 14px;
}

.uploadImgebox {
    display: inline-block;
    width: 100%;
    background: #F6F8FF 0% 0% no-repeat padding-box;
    border: 2px dashed #435FB5;
    border-radius: 8px;
    text-align: center;
    padding: 50px;
    margin-bottom: 20px;
}
.uploadImgeboxNew {
    display: inline-block;
    width: 100%;
    background: transparent;
    border: 2px dashed #29292a;
    border-radius: 8px;
    text-align: center;
    padding: 50px;
    margin-bottom: 20px;
}
.uploadImgeboxNew .uploadText {
    color: #000;
    font-size: 17px;
}
.uploadbtnn {
    background: #2F4894 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    padding: 9px 40px;
    font-weight: 500;
    display: inline-block;
    margin: 0;
    cursor: pointer;
}

.uploadText {
    color: #364F9F;
    font-size: 12px;
    margin: 0;
}

.uploadProfile_ {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 8px 17px;
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.uploadProfile_ i {
    width: 30px;
    height: 30px;
    background: #d3d2d2;
    font-size: 12px;
    text-align: center;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222;
    margin-left: auto;
}

.memberblueHead_ {
    color: #444444;
    font-size: 20px;
    font-weight: 600;
}

.memberblueSub_ {
    color: #343434;
    font-size: 14px;
    margin: 0;
}

.memberCheck_ .labelTxt_ {
    width: 100%;
}

.memberCheck_ .growMainbox_ {
    width: 100%;
    padding-left: 10px;
}

.memberCheck_ label::before {

    top: 21px;
}

.memberCheck_ input[type="checkbox"]:checked+label::after {
    top: 25px;

}

.memberCheck_ .paragraph_ {
    margin: 0;
}

.memberCheck_ .growblueHead_ {
    font-size: 14px;
}

.memberCheck_ {
    border-bottom: 1px solid #5f605e59;
    padding-bottom: 20px;
}

.registrationForm .cateSelectbox {
    border: 1px solid #d8d8d8;
    border-radius: 5px;
}

.checkmark_ input[type="radio"] {
    appearance: none;
    display: none;
}

.checkmark_ input[type="radio"]+label {
    display: inline-flex;
    font-size: 15px;
    position: relative;
}

.checkmark_ input[type="radio"]:checked+label:before {
    background: #5CC13E;
    border: 1px solid #5CC13E !important;
}

.checkmark_ input[type="radio"]:checked+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 25px;
    left: 9px;
    width: 5px;
    height: 9px;
    border: 1px solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

input[type="radio"]:checked+label::after {
    opacity: 1;
}

.billingItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.billingItems .billingItemslinks {
    font-weight: 500;
    font-size: 18px;
}

.registrationForm .cateSelectbox {
    border: 1px solid  #d8d8d8;
    border-radius: 5px;
    background-image: url(../images/memdown.svg);
}
.generalQue {
    border: 1px solid  #d8d8d8;
    border-radius: 5px;
    background: url(../images/memdown.svg)no-repeat;
    background-position: 97%
}
.cardLabel {
    color: #454545;
    font-size: 15px;
    margin-bottom: 6px;
}

.profileboldHead {
    margin: 0;
    font-size: 14px;
    color: #343434;
    font-weight: 700;
}

.profileboldgraph {
    font-size: 12px;
    color: #5E5F5E;
    margin: 0;
}

/**End Registration**/
/**Start Opportunity Css**/

.opportunityMain_ .subsmallBtnblue_ {
    width: 100%;
    font-size: 13px !important;
    font-weight: 600 !important;
    color: #fff !important;
}

.oppotunityMenu .settingLinks {
    color: #6e6e6e;
}
.oppotunityMenu .settingLinks:hover {
    color: #0a58ca;
}
.activesettingLinks span{
    color: #0a58ca;
}

.opportunityBtn_ {
    display: inline-block;
    text-align: right;
}

.opportunityBtn_ {
    display: inline-block;
    text-align: right;
    width: 19%;
}

.opportunityBtn_ .subsmallBtnblue_ {
    padding: 10px 10px;
}

.subsmallBtnblack_ {
    background: #383838;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    width: 100%;
    font-weight: 600;
}

.activeOpportunity_ .postsDetails {
    width: 50%;
    /* height: 100%;
    max-height: 150px; */
}

.activeOpportunity_ .innerSubtextSmall {

    line-height: 20px;
}
.activeOpportunity_ .listHead_{
    color: #000000;
    font-size: 13px;
    font-weight: 500;
}
.leftOpportunitycontent {
    border-right: 1px solid #D7D7D7;
    padding-right: 27px;
}
.recentlyDeleted .leftOpportunitycontent{
    width: 40%;
}
.recentlyDeleted .deleteDetails{
    padding-right: 20px;
}
.activeOpportunity_ .postContainerArea {
    border: none;
}
.post-container-area .leftOpportunitycontent{
 width: 40%;
}
.post-container-area .postsDetails {
    width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
}
.post-container-area .opportunityBtn_ {
    width: 27%;
}
.containerBoxleft {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 24px;
    position: relative;
    height: auto;
}

.oppotunityMenu .activesettingLinks img {
    /* filter: invert(62%) sepia(83%) saturate(394%) hue-rotate(60deg) brightness(91%) contrast(83%); */
    filter: invert(24%) sepia(65%) saturate(2701%) hue-rotate(208deg) brightness(90%) contrast(102%);
}

/**End Opportunity Css**/
/**Start Document Css**/

.reportHead_ {
    font-size: 13px;
    color: #343434;
    font-weight: 600;
}

.document {
    width: 100%;
    box-shadow: 0.34px 0.94px 4px #00000052;
    height: 200px;
    max-height: 200px;
}

.documentButton_ {
    display: flex;
    align-items: center;
    justify-content: center;
}

.documentButton_ .subsmallBtnblack_ {
    padding: 8px 10px;
}

.documentButton_ .subsmallBtnblue_ {
    font-size: 12px;
    font-weight: 400;
    padding: 8px 10px;
}

.postsDetails {
    display: inline-block;
    width: 100%;
    overflow: auto;
}

.registrationForm .innerTxt .linkTxt {
    cursor: pointer;
}

/* 7 april */
/* .set-scroll {
    overflow: scroll;
    height: 936px;
} */
.set-scroll::-webkit-scrollbar {
    display: none;
}

.tabindex_:focus {
    outline: none !important;
}

.infoiconText {
    position: relative;
    /* display: inline-block; */
    margin: 0 0 0 5px;
}

.infoiconText .showinfoText {
    visibility: hidden;
    width: 300px;
    background-color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 13px;
    position: absolute;
    z-index: 1;
    top: 143%;
    left: 50px;
    margin-left: -60px;
    font-weight: 400;
    font-size: 12px;
    color: #667085;
    filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.15));
    min-width: auto;
    text-align: left;
    text-transform: capitalize;
    line-height: 18px;
    z-index: 8;
}

.platinumTooltip .showinfoText {
    width: 660px;
}

.infoiconText:hover .showinfoText {
    visibility: visible;
}

/*---Eye Icon*---*/
.show-icon {
    background-image: url('/public/img/Show.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 15px;
    width: 15px;
    position: absolute;
    bottom: 17px;
    right: 23px;
    cursor: pointer;
}

.hide-icon.hide-icon {
    background-image: url('/public/img/Hide.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    z-index: 4;
    height: 15px;
    width: 15px;
    position: absolute;
    bottom: 17px;
    right: 23px;
    cursor: pointer;
}

.signupForm .form-group {
    position: relative;
}

/*---Eye Icon End*---*/
#homescreen .modal-content {
    border-radius: 15px;
}

.passHeading_ {
    font-weight: 600;
    color: #3B57A9;
    font-size: 30px;
}

.detailsContainer_ label {
    color: #5E5F5E;
    font-size: 14px;
    margin: 0 0 8px;
}

.detailsContainer_ .primaryBtn {
    font-size: 15px;
}

#homescreen .modal-body {
    padding: 20px;
}

.modal {

    backdrop-filter: blur(3px);
}

.modal-backdrop.show {
    opacity: .8;
}
.juiceBarImg{
    width: 82px;
    height: 82px;
}
.whatPeopleSay .owl-nav {
    display: block !important;
    position: absolute;
    top: 50%;
    bottom: 0;
    vertical-align: middle;
    width: 100%;
    display: none !important;
}

.whatPeopleSay .owl-carousel .owl-prev {
    background: #343434 !important;
    width: 40px;
    height: 40px;
    border-radius: 50px !important;
    position: absolute;
    left: 15px;
}

.whatPeopleSay .owl-carousel .owl-next {
    background: #343434 !important;
    width: 40px;
    height: 40px;
    border-radius: 50px !important;
    position: absolute;
    right: 44px;
}

.whatPeopleSay .owl-carousel .owl-prev span {
    font-size: 37px !important;
    color: #fff;
    line-height: 29px;
}

.whatPeopleSay .owl-carousel .owl-next span {
    font-size: 37px !important;
    color: #fff;
    line-height: 29px;
}
.whatPeopleSay .owl-dots{
    margin-top:20px;
}
.whatPeopleSay .owl-dots .owl-dot.active span{
    background-color: #405cb1 !important;
}
.crossfile {
    border-radius: 4px !important;
    background: #dfdfdf !important;
    font-size: 14px !important;
}

.dashboardRightArea .certificationContainer_ {
    height: 333px;
    overflow: auto;
}

.postrate_ {
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    color: #343434;
    display: flex;
    align-items: center;
}

.postrate_ span {
    margin-right: 8px;
    cursor: pointer;
}

.commentBox_ .customFormControl {
    padding-top: 14px;
    height: 50px;
    padding-right: 25px;
}
.messageTextarea{
    display: flex;
    align-items: center;
}
.messageTextarea .form-group{
    display: inherit;
    width: 100%;
}
.messageTextarea .postUser{
    margin-right: 10px;
    height: 40px;
    width: 40px;
}
.messageTextarea .customFormControl{
    padding:10px 40px 10px 10px !important;
    height: 40px;
    /* border-radius: 50px; */
}
.messageTextarea .sendIcon_{
    bottom: 14px;
}
.sendIcon_ {
    position: absolute;
    /* z-index: 9; */
    right: 24px;
    bottom: 25px;
    cursor: pointer;
}

.messageiconn {
    cursor: pointer;
}

.fileupimg {
    cursor: pointer;
}

.likepost_ {
    cursor: pointer;
}

.commentBox_ {
    position: relative;
    padding: 0 1rem;
}

.likethumb_ {
    margin-left: auto;
}

.posttedUser {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.posttedUserUnder{

    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.loadMOre {
    font-size: 12px;
    color: #2F4894;
    font-weight: 600;
}

.postedImg__ {
    width: 100%;
    height: 225px;
    object-fit: cover;
}

.businessLink_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.businessText {
    font-size: 12px;
    display: block;
    margin: 0 0 3px;
    font-weight: 400;
    color: #4C4C4C;
}

.imagepost_ {
    width: 100%;
    height: 100%;
    /* object-fit: cover;
    max-height: 540px;
    min-height: 540px; */
}
.imagepost_.profilePostImage{
    max-height:650px;
}
.commentText {
    font-weight: 500;
    font-size: 9px;
}

/* .listComments {
    height: 462px;
    overflow-y: auto;
} */

.dashboardComments textarea {
    padding: 12px 10px !important;
}

.btn-close {
    font-size: 10px;
}

/*Start Post Opportunity Css*/

.postHeading {
    font-size: 22px;
    color: #343434;
    padding: 0 13px 10px;
}

.postopportunity_label {
    font-size: 15px;
    color: #343434;
    margin-bottom: 10px;
    font-weight: 600;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../images/calendar.svg') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
    background-repeat: no-repeat;
}

.opportunity {
    border: 1px solid #6e6e6e !important;
    background-image: url(../images/opportunity.svg) !important;
}

.submitopportunity {
    background: transparent linear-gradient(0deg, #388E1E 0%, #4EBC2D 100%);
    border-radius: 8px;
    color: #fff !important;
    font-size: 18px;
    border: none;
    padding: 12px 32px !important;
    display: block;
    width: 29%;
    font-weight: 500 !important;
    margin-left: auto;
    margin-right: 30px;
}

/*End Post Opportunity Css*/
.customPagination .pagination ul {
    display: flex;
    width: 100%;
    justify-content: right;
}

.spinner-grow.text-success {
    color: #3B56A7 !important;
}

/*Start Account Message Css*/
.opportunityMsgHeight{
    margin-bottom: 40px;
}
.opportunityMsgHeight .containerBox{
    height: calc(100vh - 174px) !important;
    overflow: auto;
}
.opportunityMsgHeight .MessageInbox_{
    height: 634px;
}
.messageListing .discription_ {
    width: 186px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    position: relative;
}


.postionlabel {
    position: absolute;
    right: 0;
    top: 0;
}

.postContainerArea:nth-child(1) {
    border-top: none;
}

.MessageInbox_ {
    height: 578px;
    overflow-y: auto;
}

/* .messageContain {
    height: calc(100vh - -78px);
} */

.MessageInbox_::-webkit-scrollbar {
    display: none;
}

.messageListing .postionlabel .dropdown {
    text-align: right;
}

.premiumcom .cardBodyArea {
    width: 59%;
}

.userProfile {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 8px;
}
.gmailSubject{
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}
.gmailHeader li{
    display: inline-block;
    padding:10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right: 20px;
}
.gmailHeader li img{
    padding-bottom: 5px;
}
.gmailHeader li.hideLabel{
    background-color: #80808045;
    padding: 10px;
    border-radius: 37px;
    width: 40px;
    height: 40px;
}
.gmailHeader li:last-child{
    margin-right: 0;
}
.gmailHeader .checkBoxTxt{
    align-items: start !important;
}
.gmailHeader .dropdown-container .checkBoxTxt:before {
    margin-top: 5px;
}
.dropdown-container {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {

  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .dropdown-container:hover .dropdown-content {
    display: block;
  }
.dropdown-container .checkBoxTxt:before {
    border: 1px solid #D3D3D3;
    padding: 8px;
    margin-right: 9px;
    border-radius: 5px;
}

.messageBox_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(95 96 94 / 20%);
}

.userName {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}

.userType {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
}

.businessType {
    margin: 0;
    font-size: 14px;
    color: #3B56A7;
    font-weight: 700;
}

.optionBox {
    height: 30px;
    width: 30px;
    object-fit: cover;
    cursor: pointer;
}

.timeDetail {
    margin: 0;
    font-size: 12px;
    color: #000000;
}

.messageDetail .innerSubtextSmall {
    font-size: 15px;
    color: #000000;
    margin: 0;
    font-weight: 500;
}

.timeingDetails {
    font-size: 15px;
    color: #3B56A7;
    padding-left: 20px;
}

.messageDetail .discription_ {
    font-size: 15px;
    margin: 0;
    color: #000000;
    /* word-break: break-all; */
}

.messageDetail .primaryBtn {
    font-size: 13px;
    padding: 5px 20px !important;
}

.disable_ {
    opacity: 0.5;
}

.company_info {
    font-size: 17px;
    color: #435FB5;
    margin: 0;
    font-weight: 600;
}

.company_option {
    color: #1C3276;
    font-size: 18px;
    font-weight: 600;
}

/*===Start Compose Labels ===*/
.compose_labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.labels_status {
    color: #A9A9A9;
    font-size: 14px;
    padding: 6px 0;
}

/*===Start Add Labels Modal===*/
#Addlabels .modal-content {
    /* right: 390px;
    top: 130px; */
}

#Addlabels .modal-header {
    border-bottom: none !important;
    padding-bottom: 0;
}

#Addlabels .btn-close {
    display: none;
}

.addLabels_heading {
    color: #435FB5;
    font-size: 22px;
    font-weight: 700;
}

.addLabelIcon {
    cursor: pointer;
}

.CancelLabel {
    font-size: 13px;
    color: #343434;
    font-weight: 500;
}

#Addlabels .subsmallBtnblue_ {
    font-size: 13px;
    font-weight: 600;
    margin-left: 15px;
}

/* .Business_details{
    height: 100%;
    max-height: 300px;
    overflow: auto;
} */
.dropdown-item:focus,
.dropdown-item:hover {
    color: #FFFFFF;
    background-color: #2F4894;
}

.dropdown-item {
    cursor: pointer;
}

/* Start Proposal Submission */
.company_heading {
    font-size: 12px;
    color: #343434;
    font-weight: 600;
}

.company_type {
    font-size: 12px;
    color: #364F9E;
    font-weight: 600;
    padding-left: 5px;

}

.company_details {
    font-size: 12px;
    color: #ACACAC;
    font-weight: 500;
    padding-left: 5px;
}

.submitProposal .primaryBtnSmall {
    font-size: 22px;
}
.submit-proposal .primaryBtnSmall{
    font-size: 14px;
}
.serverMigration {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.server-list {
    display: flex;
    align-items: center;
    margin: 0;
}

.imgserver_ {
    margin-right: 10px;
}

.server-list .company_details {
    font-weight: 400;
    color: #9A9A9A;
}

.server .headingSmall_ {
    font-weight: 700;
}

.server .innerSubtextSmall {
    font-size: 12px;
}

.viewBtn {
    color: #FFFFFF;
}

.viewBtn:hover {
    color: #FFFFFF;
}

.read-more-more-module_btn__33IaH {
    font-size: 15px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    line-height: 20px !important;
}

.read-more-more-module_btn__33IaH::after {
    background: #2F4894;
}

.activeOpportunity_ .deleteDetails {
    margin-left: 20px;
    width: 100%;
}
.wordLimit{
    overflow: hidden !important;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.advanceDetails {
    height: 100%;
    /* max-height: 55px; */
    overflow: auto;
    /* margin-bottom: 30px; */
}
.advanceDetails > div{
    padding-right: 5px !important;
}
.recentlyDataList{
    margin-top: 15px;
}
.recentlyDataList li{
    display: inline-block;
    margin-right: 5px;
}
.recentlyDataList .recentlyBtn{
    padding:5px 15px;
    font-size: 10px;
}
.recentlyBtn{
    background-color: #f0f0f0;
    font-size: 14.14px;
    color: #606060;
    padding:6px 21px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.industryGroupBtn {
    background-color: #f0f0f0;
    font-size: 13px;
    color: #60606087;
    padding: 12px 17px;
    border-radius: 5px;
    margin-bottom: 5px;
    display: flex !important;
    align-items: center;
    justify-content: start;
    /* min-height: 51px; */
    text-align: left;
    width: fit-content;
}
.opportunityComtent_ .wordLimit {
    margin: 10px 0;
}
.sendBox {
    margin-top: 30px;
    position: absolute;
    width: 93%;
    bottom: 0px;
    left: 0;
    margin: 0 auto;
    right: 0;
}

.sendBox .form-control {
    height: 55px;
    border: 1px solid #ced4da !important;
    padding-right: 54px;
}

.form-control:focus {
    border-color: none !important;
    outline: 0;
    box-shadow: none;
}

.sendIcon {
    position: absolute;
    right: 10px;
    bottom: 40px;
    cursor: pointer;
    width: 13px;
    z-index: 1;
}
.sendBox .uploadProfile_ {
    margin: 3px 0;
}

.uploadIcon {
    position: absolute;
    right: 30px;
    bottom: 40px;
    width: 18px;
}

.advanceDetails::-webkit-scrollbar {
    width: 2px;
}

.activeOpportunity_ .postsDetails::-webkit-scrollbar {
    width: 2px;
}

.companyprofilebanner {
    height: 100%;
    /* max-height: 650px; */
    /* object-fit: cover; */
}
.customLoader{
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.customLoaderProfile{
    height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.companyInfo {
    height: 100%;
    /* max-height: 150px; */
    /* overflow: auto; */
}

.companyInfo::-webkit-scrollbar {
    width: 2px;
}

.motionModalImg {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

.ComposeMessage .customFormControl {
    width: 100%;
    border: 1px solid rgba(76, 76, 76, .19) !important;
    padding: 12px;
    border-radius: 5px;
    font-size: 12px;
}

.slectedCompany {
    height: 100%;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 20px;
}

.companyList {
    background: #2F4894;
    color: #FFF;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 10px;
    display: inline-block;
    margin: 10px 10px 10px 0;
    cursor: pointer;
}

.mainHeading {
    color: #6E6E6E;
    font-size: 22px;
    font-weight: 600;
    padding: 1rem 0;
    margin: 0;
}

.sendMessage .primaryBtnSmall {
    /* width: 100%; */
    /* max-width: 150px; */
    font-size: 13px;
    /* margin: 10px 0; */
}

.uploadImgebox .mainHeading {
    font-size: 12px;
}

/* .opportunityButton {
    max-width: 25%;
} */

.certificationContainerBox_ {
    margin-bottom: 20px;
    padding-right: 6px;
}
.certificateConInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.certificateConInner h5{
    font-size: 16px;
    color:#393939;
    font-weight: 400;
}
.read-more{
    font-weight: 600;
    cursor: pointer;
}
.certificateConInner h4{
    font-size: 14px;
    color:#000;
    font-weight: 500;
}
.certificateConInner .uploadText{
    color: #737373;
}
.certificateConInner .opportunitiesBox{
    border: 1px solid #CCCCCC !important;
    padding:6px;
}
.opportunitiesBox {
    width: 100px;
    height: 100px;
    /* max-width: 100px; */
    /* max-height: 100px; */
    border-radius: 10px;
    margin-right: 10px;
    min-height: 100px;
    min-width: 100px;
    object-fit: cover;
}

.userOppertunities {
    height: 100%;
    max-height: 500px;
    overflow: auto;
}

.deviserCompany_ .subsmallBtnblack_ {
    font-size: 14px;
    font-weight: 500;
}

.resourceMenu .aboutLinks {
    text-transform: capitalize;
}

.resourceMenu .dropdownBox {
    border-bottom: 1px solid #ccc;
    margin: 0 10px;
}

.resourceMenu .dropdownBox:last-child {
    border: none;
}

.resourceMenu .dropdown-item {
    cursor: pointer;
    padding: 10px;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px;
    border: none;
    cursor: pointer;
}
.aboutMenusContainer .dropdown-toggle {
    background-color: transparent;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 10px;
    list-style-type: none;
}

.dropdown-content li {
    cursor: pointer;
}

.dropdown-content li:hover {
    background-color: #f1f1f1;
}

.dropdown-content .checkBox:checked + .checkBoxTxt:before {
    background: transparent !important;
    border: 2px solid #D3D3D3;
}
.dropdown-content .checkBox:checked + .checkBoxTxt:after {
    border: solid #747272;
    border-width: 0 2px 2px 0;
    top: 16px;
    left: 7px;
}
.dropdown-content input[type="search"]{
    font-size: 14px;
    border-bottom: 1px solid gray !important;
    padding: 4px 0px 4px 6px;
    border: none;
}
.dropdown-container .createNew{
    font-size: 12px;

}
.endorTabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.endorTabs .react-tabs__tab {
    /* border: none !important; */
    font-size: 13px;
}
.react-tabs__tab-list{
    border-bottom: 0 !important;
}
.react-tabs__tab{
    background: transparent !important;
    padding: 10px 20px !important;
    font-size: 13px;
    font-weight: 500;
    margin-right: 10px;
    border-radius: 6px;
    border:1px solid  #BFBFBF !important;
    color:#BFBFBF;
}

.react-tabs__tab--selected {
    background: #2F4894 !important;
    border-radius: 5px !important;
    padding: 10px 20px !important;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}

.endorsement_ .userProfile {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 10px;
}

.endorsement_ .innerSubtextSmall {
    color: #000;
}

.buyerService li,   
.supplierService li,
.bussinessRelation li,
.opportunites li {
    list-style: none;
    margin: 0 10px;
}
.bussinessRelLeftImg{
    display: flex;
    justify-content: space-between;
}
.privacyHeading {
    font-size: 18px;
    margin: 10px 0;
}

.endorsement_ .messageDetail {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}
.endorsement_ .messageDetail:last-child {
    border-bottom: 0;
}
.endorsement_ .react-tabs__tab-panel--selected {
    max-height: 405px;
    overflow: auto;
}

.endorsementsButton .subsmallBtnblue_ {
    background: #ffffff;
    font-size: 18px;
    color: #000;
    font-weight: 500;
}

.endorsementsButton .subsmallBtngreen_ {
    background: #5CC13E;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
}

#homescreen .modal-backdrop {
    z-index: -2 !important;
    background-color: #000 !important;
}
.receiveAclamation{
    margin-top:10px;
}
.approveEndorsement .receiveAclamation .primaryBtn{
    font-size: 13px !important;
	padding: 10px !important;
    width: 100% !important;
    margin-right: 0;
    border-radius: 5px;

}
.approveEndorsement .receiveAclamation .secondaryBtnOuter{
    font-size: 13px !important;
	padding: 10px !important;
    width: 100% !important;
    margin-right: 0;
}
 .approveEndorsement .cardImg img {
	height: 88px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
    padding: 2px;
}

.approveEndorsement .cardImg {
    margin-right: 15px;
}

.approveEndorsement .cardBodyArea {
    padding: 0;
}

.approveEndorsement .CardContainer {
    padding: 21px;
}

.approveEndorsement .primaryBtn {
	font-size: 12px;
	padding: 5px 10px !important;
	font-weight: 600 !important;
	margin-right: 10px;
}
.approveEndorsement .secondaryBtnOuter {
	font-size: 12px;
	padding: 5px 10px !important;
	font-weight: 600 !important;
	margin-right: 10px;
}
.approveEndorsement p {
	color: #000;
	font-size: 14px;
	font-weight: 500;
}
.approveEndorsement .cardBodyArea h5 {
	font-size: 18px;
	letter-spacing: 0px;
}
.approveEndorsementButton {
    display: flex;
    justify-content: end;
}

.suggestionBox {
    white-space: pre;
    padding: 0;
}

.suggestionBox .primaryBtn {
    background-color: #4EBC2D;
    border-radius: 0;
    padding: 8px 27px !important;
}

.suggestionModal .primaryBtn {
    background: #4EBC2D;
    font-size: 13px;
    padding: 10px 20px !important;
    margin-left: auto;
    font-weight: 600 !important;
}

.messageDetailRight .discription_ {
    text-align: justify;
}

/* .messageDetailRight .innerSubtextSmall {
    justify-content: end;
} */

.messageDetailRight .userProfile {
    margin-left: 0.5rem;
}

.messageDetailRight .timeingDetails {
    padding-right: 20px;
}
.newReply{
    display: flex;
    align-items: start;
}
.newCompanyName{
    color: #6B6B6B !important;
}
.newComment{
    color: #000 !important;
    word-break: break-all;
}
.joinHeading{
    font-size: 18px;
}
.userNameCommentTime{
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.chatContainer {
    height: 100%;
    height: calc(100vh - 500px);
    overflow: auto;
    margin-bottom: 50px;
}
.newChatHeight{
    height: calc(100vh - 200px) !important;
}
.newChatHeight .userProfile{
    width: 60px;
    height: 60px;
    border: 1px solid #c3c3c3;
    padding: 1px;
    object-fit: cover;
}
.labelRemoveBtn{
    display: block;
    width: fit-content;
    background: #bbbbbb80;
    border-radius: 4px;
    font-size: 13px;
    padding: 2px 5px;
    margin-right: 0;
    color: #00000080;
    margin-right: 0;
}
.labelRemoveIcon{
    cursor: pointer;
}
.labelRemoveIcon:hover{
    color:#fff;
}
.labelList li{
    display: inline-block;
    padding-right: 5px;
    padding-bottom: 5px;
}
#ComposeMessage .primaryBtnSmall {
    /* width: 100%; */
    /* max-width: 110px; */
    font-size: 13px;
    /* margin: 10px; */
    padding: 10px 20px;
    font-weight: 600;
}

.suggestionIcon {
    width: 20px;
    margin-left: 10px;
}

.designLeft_ .companyDetailsLinks {
    padding: 0;
    color: #6E6E6E !important;
}

#messageResponse .headingTitleSmall_ {
    align-items: center;
}

#messageResponse .designImg_ {
    border: 1px solid red;
    border-radius: 8px;
}

#messageResponse .imgproduct_ {
    min-height: 100px;
    max-height: 100px;
    border-radius: 8px;
    object-fit: cover;
    padding: 10px;
}

#messageResponse .modal-header {
    border: none !important;
}

/* #messageResponse .btn-close{
    display: none;
} */
input[type="number"] {
    resize: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.moreOption {
    width: 20px;
    height: 20px;
    margin-left: auto;
}

.messageDetailRight {
    position: relative;
}

.messageDetailRight .uploadedImage {
    width: 70px;
    height: 70px;
    position: absolute;
    right: 70px;
    top: 32px;
    border-radius: 5px;
}

.uploadIcon {
    text-align: right;
    margin-left: auto;
    border-radius: 10px;
    object-fit: cover;
    z-index: 1;
}

.messageDetail {
    position: relative;
    margin-bottom: 15px;
}

.messageDetail .uploadedImage {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 70px;
    top: 32px;
    border-radius: 5px;
}

/* .dashboardButton .primaryBtnSmall {
    margin-right: 10px;
    font-size: 14px;
} */

.customRadioBox {
    display: flex;
    align-items: center;
}

.radio {
    margin-right: 20px;
}

#waitListModal .primaryBtn {
    font-size: 12px;
    margin: 0 auto;
}

#waitListModal .modal-header {
    padding: 1rem 1rem 0 !important;
    border-bottom: none !important;
}

.customRadioBox [type="radio"]:checked,
.customRadioBox [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.customRadioBox [type="radio"]:checked+label,
.customRadioBox [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    display: inline-block;
    color: #000000;
    font-weight: 500;
    line-height: 20px;
}

.customRadioBox [type="radio"]:checked+label:before,
.customRadioBox [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #000;
    border-radius: 100%;
    background: #fff;
}

.customRadioBox [type="radio"]:checked+label:after,
.customRadioBox [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #000000;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.customRadioBox [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.customRadioBox [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.selectIndex .searchBox{
    z-index: 1;
}

.searchBox {
    position: relative;
    width: 100%;
    max-width: 300px;
    z-index: 3;
    /* box-shadow: 0px 2px 5px #ccc; */
}
.authNavSearchBox{
    max-width: 185px;
}
.inputClose {
    position: absolute;
    right: 0;
    top: 8px;
    display: none;
}

.searchBtn {
    position: absolute;
    left: 0;
    top: 8px;
}

.searchBtn .las {
    font-size: 20px;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 0;
}

.rounded {
    padding: 6px 30px;
}
.linkdinBtn{
    border-radius: 32px !important;
    color:#000000bf;
}
.linkdinBtn.subsmallBtnblue_{
    color:#fff;
}
.searchBoxUsers {
    background: #ffffff;
    text-align: left;
    max-height: 160px;
    overflow: auto;
    padding: 11px 5px;
    width: 100%;
    max-width: 300px;
    z-index: 8;
    position: absolute;
    left: 0;
    top: 48px;
    border-radius: 10px;
    display: none;
}

.searchBoxUsers.active {
    display: block;
}

.searchBoxuser {
    display: flex;
    align-items: center;
}

.searchCompany {
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 10px;
}

.updateBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 20px;
    background-image: linear-gradient(to right, #3B55A7, #2B438E);
    border-radius: 8px;
}

.updateHeading {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.updateSubheading {
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    margin: 0;
}

.updateIcon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.listMain {
    display: flex;
    align-items: start;
    margin: 17px 0;
}
.listMain .tickMark{
    margin-top:5px;
}
.whatPeopleSay .aboutusSubtext {
    font-size: 29px;
    font-weight: 300;
    line-height: 32px;
    color: #595959;
    text-align: center;
}

.homeBanner .btn-group {
    margin-top: 5.5rem !important;
}

.containerBoard {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    align-items: start;
}

.boardImg {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    border-radius: 5px;
}

figure {
    margin: 0;
}

.forgotPassword {
    color: #364F9F;
    font-size: 16px;
    font-weight: 500;
    padding-top: 15px;
}

.stepBox {
    display: flex;
    align-items: center;
}

.backIcon {
    font-size: 32px;
    margin-right: 15px;
}
.tab-buttons-membership{
    margin-bottom: 20px;
    display: flex;
    width: 100%;
}
.tab-buttons-membership .membershipTabs.active{
    border-bottom: 2px solid #1c3b9bf5;
    color: #435FB5;
}
.tab-buttons-membership .membershipTabs.active button{
    color: #435FB5;
}
.tab-buttons-membership .membershipTabs{
    border-bottom: 2px solid #E1E1E1;
    width: 50%;
    text-align: center;
}
.tab-buttons-membership .membershipTabs button{
    font-size: 22px;
    font-weight: 600;
    color: #c4c4c4;
}
/* Design Updates */
.aboutContainer .bannerHead {
    font-size: 58px;
}

.aboutContainer .bannerTxt {
    font-size: 26px;
}

.aboutFounder .bannerTxt {
    font-size: 17px;
    text-align: justify;
}

.aboutLinks {
    font-size: 19px;
}

.aboutFounder .bannerHead {
    font-size: 42px;
}

.aboutUs .heading_Blue {
    font-size: 42px;
}

.mission .innerSubtext {
    font-size: 17px;
    text-align: left;
}

.newsPress .bannerHead {
    font-size: 42px;
}

.newsCard .innerSubtextBlue {
    color: #2f4ca6;
    font-size: 17px;
}

.newsCard .readMore {
    font-size: 16px;
    color: #2f4ca6;
    font-weight: 400;
}

.sucessCompny_ .bannerTxt {
    font-size: 19px;
}

.sucessCompny_ .Boldtxt {
    font-size: 22px;
}

.ralevanceRight_ .bannerTxt {
    font-size: 18px;
}

.fontNineteen {
    font-size: 19px;
}

.workDealing .headingBluemain_ {
    font-size: 30px;
}

.workDealing .termspara_ {
    font-size: 19px;
    text-align: justify;
}

.topheadingBites p {
    font-size: 17px;
}

.mainSoundbites_ .listHead_ {
    font-size: 15px;
}

.mainSoundbites_ .headingSmall_ {
    font-size: 20px;
}

.opportunites .innerSubtext {
    font-size: 16px;
}

.topprofileBanner {
    position: relative;
}

.chnageProfile {
    background: #FFF;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 4px;
}

.uploadIcon_ {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    left: 2px;
}

.homeRelevance {
    background: #fff;
}
#buyers{
    background: #f7f7f7;
}
/* .buyerimgContainer {
    margin-top: 5.3rem;
} */
#community .listMain {
    margin: 14px 0;
}
#community .innerSubtext {
    font-size: 16px;
}
.homePage .container {
    /* max-width: 1400px; */
}

.bitesCrad video {
    width: 100%;
    height: 180px;
    border-radius: 10px 10px 0px 0px;
}

.membershipRegistration_ .memberblueSub_ {
    padding-right: 10px;
}

.uploadProfile_ video {
    width: 78px;
}

.palnmemberInner {
    background: #fff;
    border-radius: 10px;
}

.palnmemberInner .memberImage {
    width: 100%;
    border-radius: 10px 10px 0 0;
    height: 194px;
    object-fit: cover;
}

.memberMiddle .memberHead_ {
    font-weight: 400;
}

.memberMiddle {
    display: inline-block;
    width: 100%;
    padding: 19px 30px;
}

.toppalnHead_ {
    padding-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;
}

.featuresList_ {
    margin-top: 34px;
}

.memberMiddle .featuresList_ .growblueHead_ {
    font-size: 19px;
}

.licenceUser_ {
    margin-top: 30px;
}

.licenceHead_ {
    color: #2e2e2e;
    font-size: 15px;
    font-weight: 500;
}

.costbox_ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #2f4794;
    color: #fff;
    padding: 15px 15px;
    border-radius: 8px;
}

.costHead_ {
    font-size: 13px;
    margin: 0;
    color: #fff;
}

.costboldHead {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
}

.yearCost_ {
    display: flex;
    justify-content: start;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.yearCost_ h4 {
    font-size: 15px;
    font-weight: 500;
}

.yearCost_ h3 {
    font-size: 17px;
    font-weight: 700;
    margin-left: auto;
}
.yearCost_ p{
    font-size: 11px;
}
.pricehead_ {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
}

.memberMiddle .subsmallBtngreen_ {
    width: 100%;
    font-size: 17px;
    background: #22ba22;
}

.featureListbox_ {
    min-height: 455px;
    height: 100%;
}

.accountDashboard .cardBodyArea h5 {
    font-size: 16px;
    letter-spacing: 0px;
}

.faq-row-wrapper {
    padding: 10px 30px;
}
.frequentyQuestion_ .faq-row-wrapper {
    padding: 10px 6px !important;
}
.imgContainer {
    position: relative;
}

.goIcon {
    width: 100%;
    max-width: 70px;
    position: absolute;
    right: 30px;
    bottom: 35px;
    cursor: pointer;
}

.goIconLeft {
    width: 100%;
    max-width: 70px;
    position: absolute;
    bottom: 35px;
    left: 25px;
    cursor: pointer;
}

.memberblueHead_ {
    display: flex;
    align-items: center;
}

.videoBox {
    border: 10px solid #dddddd;
    border-radius: 10px;
    margin-top: 8rem;
}

.sudayFunday {
    height: 100%;
    min-height: 172px;
    max-height: 172px;
    border-radius: 8px 8px 0 0;
}

/* Start Edit profile */
.flexBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Editprofile .motionSec {
    padding: 20px;
}

.editIcon {
    width: 30px;
    height: 30px;
    margin-left: 12px;
    cursor: pointer;
}

.profileHeading {
    font-size: 18px;
    margin: 0;
    color: #444444;
    font-weight: 600;
}

.profilesubhead {
    font-size: 18px;
    color: #2c2e31;
    margin: 0;
}

.profileBox {
    border-bottom: 1px solid #cccc;
}

.userInfo {
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.userInfo_ {
    padding: 20px 0;
}

.userprofileName {
    font-size: 21px;
    color: #39507b;
    font-weight: 500;
}

.userEmail,
.userPassword {
    font-size: 16px;
    color: #464646;
    margin: 0;
}

.companyprofileInfo {
    font-size: 17px;
    color: #212529;
    font-weight: 700;
}
.companyProfile__{
    border:1px solid #e7e7e7;
    display: inline-block;
    padding:14px;
    border-radius: 7px;
}
.companyProfile__ img{
    width: 100%;
    max-width: 116px;
    height: 65px;
}
.viewProfile_ .followBtn {
    background: #4d6ac4;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
}
.viewProfile_ .appliedbtn {
    background: #838383;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 13px;
    color: #fff;
    font-weight: 600;
}

.sendMessageBtn{
    background-color: #22ba22 !important;
}
.profileDes{
    color: #757575 !important;
    line-height: 28px;
}
.lookingSuppllierCard {
    position: relative;
    background: #4d6ac4;
    border-radius: 12px;
}
.lookingSuppllierCard .lookingSupplierCardData {
    background: #4d6ac4;
    text-align: center;
    padding: 13px 0;
    border-radius: 0 0 12px 12px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.lookingSuppllierCard{
    background: url('../images/supplierNew.svg') no-repeat;
    height: 229px;
    background-size: 100%;
    border-radius: 12px !important;
}
.bussinessResourceInner{
    background: url('../images/businessImg_.webp') no-repeat;
    height: 188px;
    background-size: 100%;
    border-radius: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bussinessResourceInner p{
    background: #51b134;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 16px;
    cursor: pointer;
}
.lookingSupplierCardData p{
    font-size: 17px;
    color: #fff;
    cursor: pointer;
}
.lookingSupplierCardData a{
    color: #fff;
    font-weight: 600;
}
.aboutCompanyView .headingTitle_{
    font-size: 23px;
}
.aboutCompanyView .blueinnerSubtext{
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    max-width: 138px;
    display: inline-block;
}
.aboutCompanyView li {
    justify-content: start;
}
.aboutCompanyView .listHead_{
    font-weight: 500;
    font-size: 16px;
}
.companyDetails {
    font-size: 18px;
    font-weight: 500;
    color: #191919;
}

.Certifications {
    margin-right: 10px;
}
.certificateIcon_{
    width: 50px;
    height: 50px;
}
.certificateWidth{
    width: 60px;
    display: contents;
}
.profileBox:last-child {
    border-bottom: none;
}

.footerImg {
    width: 210px;
}

.postsDetails .memberName {
    font-size: 14px;
}
.newMemberName{
    font-size: 15px !important;

}

#ComposeMessage .dropdown-menu {
    max-height: 165px;
    overflow: auto;
    width: 100%;
}

/* Start New member */
.newMembership .motionSec {
    padding: 20px;
}

.newMembership .search_group {
    flex-wrap: initial;
    padding: 0;
    border-radius: 8px;
}

.newMembership .custoInput {
    padding-left: 10px;
}

.newMembership .pencardBottom {
    margin-top: 10.5rem !important;
}

.messageBox_details .userName,
.addLabelList .labels_status,
.innerSubtextSmall {
    cursor: pointer;
}

.messageListing {
    position: relative;
    background: #fff;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
}

.nav-link.active {
    color: #61b95e !important;
}

.promotionalCode .search_group {
    flex-wrap: nowrap;
    padding: 0px 0px 0px 15px !important;
    border-radius: 8px;
    background: #fff;
}

.multiSelectContainer input {
    font-size: 12px;
}

.multiSelectContainer li {
    padding: 10px;
    font-size: 12px;
}

.approveEndorsement {
	display: flex;
	align-items: center;
	padding: 10px;
	margin-bottom: 10px;
	width: 100%;
	background: #fff;
    border-radius: 10px;
    -webkit-box-shadow: 1px 1px 12px 2px rgba(245,242,245,1);
    -moz-box-shadow: 1px 1px 12px 2px rgba(245,242,245,1);
    box-shadow: 1px 1px 12px 2px rgba(245,242,245,1);
}
.approveEndorsement .communityMaxHeigh{
    height: 100%;
    max-height: 133px;
    overflow: auto;
}
.approveEndorsement .communityMaxHeigh::-webkit-scrollbar-track {
    background-color: #4c4c4c;
}
/* #allPostLikeUser .modal-body{
    height: 100%;
    max-height: 300px;
    overflow: auto;
} */
.likedUserImg {
    width: 100%;
    height: 50px;
    max-width: 50px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    object-fit: cover;
}

.likedPostuser {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}

.custom-file-input {
    display: inline-block;
    position: relative;
}

.input-file {
    display: none;
}

.upload-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    vertical-align: middle;
}

.input-file+label {
    cursor: pointer;
    /* background-color: #f0f0f0; */
    padding: 8px 12px;
    border-radius: 4px;
    /* border: 1px solid #ccc; */
    display: inline-flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

/* .input-file + label:hover {
    background-color: #e0e0e0;
  } */
.uploadProfile {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1.5px solid #d3d4d3;
    padding: 14px;
}

.uploadprofileIcon {
    position: absolute;
    right: 9px;
    top: 0px;
    background: #388e1ed9;
    color: #fff;
    padding: 4px 6px;
    border-radius: 8px 8px 8px 0;
    font-size: 12px;
    cursor: pointer;
}

#editCompanyInformationDetails .modal-dialog {
    max-width: 760px;
}

.EditIcon {
    cursor: pointer;
}

#chnagePassword .form-group {
    position: relative;
}

#chnagePassword .primaryBtn,
#editaccountuserdetails .primaryBtn,
#editCompanyInformation .primaryBtn,
#editCompanyInformationDetails .primaryBtn,
#editExistingCertifications .primaryBtn {
    font-size: 13px;
    padding: 10px 20px !important;
    border-radius: 5px;
    font-weight: 600 !important;
}
#editCompanyInformation form{
    max-height: calc(100vh - 415px);
    overflow: auto;
}
#editCompanyInformation .modal-footer{
    justify-content: center;
    /* padding-bottom: 0; */
}
.modal-footer{
    justify-content: center;
}
.searchWrapper {
    border: 1px solid #6e6e6e !important;
    padding: 12px !important; 
}
#multiselectContainerReact .searchWrapper{
    height: 100%;
    max-height: 133px;
    overflow: auto;
}
.editProfileuser {
    width: 110px;
    height: 110px;
    max-height: 100px;
    max-width: 100px;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 6px;
}
.companyIcon{
    border: 1px solid #CCCCCC !important;
}
.disable {
    background: #cfcfcf;
    color: #000;
}

.whiteInfo {
    width: 15px;
    margin-left: 10px;
}

.greyInfo {
    width: 15px;
    margin-left: 10px;
}

.postBox {
    position: relative;
}

.videoIcon {
    position: absolute;
    top: 50%;
    right: 45%;
    width: 50px;
    height: 50px;
    transform: translate(5%, -50%);
    cursor: pointer;
}

.newBox {
    border: unset !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor:not-allowed;
}

.cardBox {
    background-image: url(../images/pepcicoImgOne.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    height: 100%;
    min-height: 460px;
    position: relative;
}
.cardBox button{
    padding:10px 20px !important; 
    font-size: 13px;
    font-weight: 600;
}
.cardBox p{
    font-size: 15px;
}
.cardBox b{
    color:#fff !important;
}
.textBox {
    position: absolute;
    bottom: 0;
    padding: 20px;
}
.textBox h6{
    font-size: 22px;
    color: #fff;
}
.textBox b{
    color: #1C3276;
}
.textBox p{
    color: #fff;
    font-size: 14px;
}
.secondaryBtnGrey {
    font-size: 13px;
    border-radius: 5px;
}
/* start Dashboard page */
.moreOptionIcon {
    font-size: 24px;
    color: #3b56a7;
    cursor: pointer;
}
.postOptionBox .dropdown-toggle {
    background-color: transparent;
    padding: 0;
}
.postOptionBox .dropdown-toggle.show {
    background-color: #3b56a7 !important;
    border-radius: 6px !important;
    margin: 0;
    height: 24px;
}

.postOptionBox .dropdown-toggle.show i {
    color: #ffffff;
}

.postOptionBox .dropdown-toggle::after {
    content: "";
    opacity: 0;
    display: none;
}
.postOptionBox .dropdown-menu {
    background-color: #3b56a7;
    border: none;
    border-radius: 10px;
    width: 260px;
}
.postOptionBox .dropdown-item:focus, .dropdown-item:hover {
    background-color: #3b56a7;
}
.postOptionBox .dropdown-item {
    color: #fff;
    padding: 2px 0;
    font-size: 12px;
}
.postOptionBox .dropdown-menu li{
    margin: 10px;
    border-bottom: 1px solid #ffffff57;
}
.postOptionBox .dropdown-menu li:last-child{
    border-bottom:none;
}
.postOptionBox .dropdown-menu.show {
    display: block;
    /* transform: translate3d(0px, 10px, 0px) !important; */
}
/* .modal {
    z-index: 9999;
} */

/* Start Comment modal */
#commentBox .modal-backdrop {
    z-index: -2 !important;
    background-color: transparent !important;
}
.comments {
    /* border: 1px solid #cccc; */
    padding: 10px;
    border-radius: 10px;
}
#commentBox .commentReply{
    display: flex;

}
#commentBox .innerSubtextSmall {
    font-size: 10px;
}
#commentBox .commentText {
    font-weight: 500;
    font-size: 9px;
    color: #2F4894;
}
.commentreply .comments {
    border: 1px solid #efefef;
    background-color: #efefef;
}
.commentReplyIcon {
    width: 25px;
}
#commentBox {
    max-width: 450px;
    margin: 1.75rem auto;
    /* margin: 0 auto; */
}
.loadMore {
    font-size: 24px;
    border: 2px solid #2F4894;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    color: #2F4894;
}
/* .footerComment{
    position: fixed;
    bottom: 0;
    width: 100%;
} */
.modal-content {
    border: none;
    border-radius: 5px !important;
    box-shadow: 0px 0px 20px #000;
}
#commentBox .headingSmall_ {
    color: #2F4894;
    padding:0;
    font-size: 22px;
    font-weight: 700;
}
.topPost .heading {
    display: flex ;
    justify-content: space-between ;
}
#commentBox .dashboardComments textarea {
    padding: 12px 19px 12px 8px !important;
}
.business_search .input-group {
    flex-wrap: nowrap;
}
.crossIconSearch {
    cursor: pointer;
 
}
/* Start Waveless section */
.memberShipSpot{
    margin:100px 0 !important;
}
.waveless .motionSec {
    padding: 20px 3rem;
}
.wavesubText{
    font-size: 24px;
    color: 000000;
    font-weight: 500;
}
.WaveLessLogo {
    width: 100%;
    max-width: 250px;
    margin: 20px 0;
}
.WaveLess ,.ColoredLogo {
    width: 100%;
    max-width: 200px;
}
.waveless .secondaryBtnOuter {
    padding: 10px 20px !important;
    font-weight: 600;

}
.waveless .secondaryBtnGrey {
    padding: 10px 20px !important;
    font-size: 13px;
    border-radius: 5px;
    font-weight: 600;
}
.spotlights .owl-nav ,.mainSoundbites_ .owl-nav{
    position: absolute;
    top: -72px;
    right: 0;
}
.Spotlights {
    width: 150px !important;
    margin: 0 auto;
    height: 150px;
    object-fit: contain;
}
.spotlights .owl-theme .owl-nav [class*='owl-'] ,.mainSoundbites_ .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF !important;
    font-size: 22px;
    margin: 5px;
    padding: 4px 7px;
    background: #1C3276;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
}
.dashboardButton .secondaryBtnOuter {
    font-size: 12px;
}
.uploadfileIcon {
    display: block;
    margin: 10px auto;
}
.uploadbtn {
    font-size: 26px;
    font-weight: 700;
}
.form-group{
    position: relative;
}
.innerSidebar .inputIcon{
    bottom: 17px;  
}
.innerSidebar .business_search, .cateSelectbox, .searchWrapper, .customFormControl{
    border-color:  #d8d8d8 !important;
}
.opportunityDatePicker .react-datepicker-wrapper{
width: 100% !important;
}
.inputIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
}
.newCalander{
    width: 25px;
    height: 25px;
}
.background-selected{
    background-color: #2f48943f; 
    transition: background-color 1s ease; 
}
.background-selected.background-new{
    background-color: unset;
}
#commentBox .dashboardComments {
    height: 100%;
    max-height: 500px;
    overflow: auto;
    padding: 0 1rem;
}
#commentBox .modal-body{
    padding:0 !important;
}
#commentBox .mainHeading {
    color: #2e4692;
    font-size: 18px;
    padding: 0;
}
.commentBox_ .customFormControl::-webkit-scrollbar{
    display: none;
}
#commentBox .loadMoreText {
    font-weight: 500;
    font-size: 12px;
    color: #2F4894;
    cursor: pointer;
}

/* .commentReplyBox{
    height: 100%;
    max-height: 300px;
    overflow: auto;
} */

.coloredHeading{
    color: #2B438E;
}


/* Style for the custom checkmark */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #5CC13E; /* Change the background color */
    border: 2px solid #333; /* Set the default border color */
    border-radius: 50%;
  }
  
  /* Style for the checkmark and border when the radio button is checked */
  input[type="radio"]:checked + .checkmark {
    background-color: #5CC13E; /* Change the background color */
    border-color: #5CC13E; /* Change the border color */
  }
  
  /* Style for the checkmark when the radio button is checked */
  input[type="radio"]:checked + .checkmark::after {
    content: '\2713'; /* Unicode checkmark character */
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px; /* Customize the checkmark size */
    color: #333; /* Customize the checkmark color */
  }
  
  /* Saved Modal */
  .saveSearchBox{
    margin-bottom: 20px;
    border-bottom: 1px solid #565656;
    padding-bottom: 20px;
  }
  .SavedIcon {
    margin-left:10px;
    width: 40px;
}
.savedTimimg{
    font-size: 18px;
    color: #565656;
    margin: 0 2rem 0 0;
}
.savedHeading{
    color:#2c2c2c;
    font-size:21px;
    margin: 0;
}
#SavedSearches  .bottomBorder_{
    margin: 15px 0;
}
#SavedSearches {
    max-width: 650px;
    margin: 1.75rem auto;
}
#SavedSearches .modal-body {
    height: 100%;
    max-height: 700px;
    overflow: auto;
    padding:1rem  2rem;
}
#SavedSearches .modal-header{
    padding: 2rem;
    border-bottom: none;
}
.saveSearchBox:last-child{
    border: none !important;
}
/* Start Membership page */
.startUps {
    background: #002c5a;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
}
.startupHeading{
    color: #fff;
    font-weight: 18px;
    font-weight: 600;
    margin: 0;
}
.startUpMain {
    background: #fff;
    border: 1px solid #646464;
    padding: 15px 20px;
    margin-top: 20px;
    border-radius: 10px;
}
.startupmoreHeading {
    color: #01346a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}
.historyBox {
    background: #fafafa;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 10px;
}
.historyText {
    color: #2c2e31;
    font-size: 16px;
    margin: 0;
}
.historyPrice {
    color: #2f3030;
    font-size: 20px;
    font-weight: 500;
}
.paymentHeading {
    font-size: 27px;
    color: #2c2e31;
    font-weight: 500;
}
.paymentSubheading {
    color: #012c5a;
    font-size: 22px;
    font-weight: 500;
}
.smallDeatils {
    color: #2c2e31;
    font-size: 15px;
    margin: 0;
}

.paymentScrolled{
    overflow: auto;
    height: 450px;
}

.primaryBtn.disabled{
    background: rgb(184, 184, 184) !important;
    cursor: not-allowed;
}
.txt25 {
    font-size: 25px;
    color: #222222;
}
.Certificationstxt20{
    font-size: 20px;
    color: #222222;
}
.diveersityRight img{
    /* height: 480px; */
    border-radius: 10px;
}
.oppsbell500{
    font-weight: 500;
}
.textHeading25 {
    font-size: 25px;
    color: #222222;
}
.supplierRight .txt14{
    font-size: 16px;
    color: #222222;
}
.supplierRight .blueHeading{
    color: #1e3785;
}
.heading31{
    font-size: 31px;
    color: #1e3785;
}
.CertificationHeading{
    color: #171717;
}
.commentsectionBox {
    display: flex;
    align-items: start;
}   
.aboutComment{
    width: 100%;
    background-color: #3e5ab110;
    padding: 10px;
    border-radius: 8px;
}
.commentreplyIcon{
    margin-left: 56px;
    margin-top: 15px;
}
.updatePlanBtn_{
    padding: 14px 16px !important;
    font-size: 17px;
    border: 2px solid #e4e4e4;
    color: #01346a;
    background: transparent;
    border-radius: 10px;
    font-weight: 600;
    width: 100%;
}
.scrollnav {
    display: inline-block;
    width: 100%;
}
.outerWrapperSmall {
    width: 100%!important;
    min-width: initial!important;
    background: #eee;
    border-radius: 10px;
    height: auto!important;
    padding: 2px 0;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}
.imgWrapperSmall {
    overflow: hidden;
    flex: 1;
    width: 100%;
    display: flex;
    height: auto !important;
    align-items: center;
    justify-content: center;
    padding: 12px;
}
.imgWrapperSmall .responsiveImage{
    height: 105px;
    object-fit: contain;
}
.titleWrapper ~ p{
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
/* .responsiveImage {
    width: 300px !important;
    height: auto;
    border-radius: 5px ;
} */
.textWrapperSmall p {
    margin-bottom: 0 !important;
}
.textWrapperSmall {
    padding: 1rem !important;
    height: auto !important;
}
.siteNameLinkWrapper {
    margin-bottom: 0;
}
.siteNameLinkWrapper a {
    color: #222!important;
}
.previewbox_ {
    position: relative;
    display: inline-block;
    width: 100%;
}
.previewiconclose {
    position: absolute;
    top: 0;
    z-index: 9;
    right: 0;
}
.newBluePmg .cardBox{
    /* background-image: url(../images/newPmj.webp) !important; */
    background-image: url(../images/burell.png) !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-color: #01346a !important;
    min-height: 525px !important;
}
.newBluePmg .cardBox h6{
    font-size: 33px;
    font-weight: 800;
}  
.newBluePmg .spotLightInner{
    width: 100%;
    max-width:95px;
    margin-bottom: 20px;
} 
.order-second{
    order:0 ;
}
.arrowLeft{
    width: 10px;
}
.sharePost{
    display: flex;
    align-items: center;
}
.oppswellTxt{
    color: #6e6e6e;
    font-weight: 400;
    margin-left: 10px;
}
.sharePost .postUser{
    width: 45px;
    height: 45px;
}
.sharedPostTxt{
    font-size: 15px;
    margin-bottom: 0;
}
#sharePost.modal-dialog{
    max-width: 650px !important;
}
#sharePost .modal-content{
    border-radius: 10px !important;
}
#sharePost textarea{
    border:none !important;
}
#sharePost .addPostTitle{
    font-size: 15px;
    font-weight: 600;
}
#sharePost .addPostSub{
    font-size: 12px;
    font-weight: 400;
}
#sharePost .reSharePostOuter,
#addNewPost .reSharePostOuter
 {
    border: 1px solid #BFBFBF;
    padding: 10px;
    max-height: 20rem;
    overflow-y: scroll;
    border-radius: 8px;
}
.sharedPostDetails{
    display: flex;
    gap: 0.5rem;
}